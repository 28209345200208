import { api } from '.';
import { USER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';

/**
 * @param {string} userId
 */
export const getResources = userId => ({
  [USER_RESOURCE]: userId,
});

/**
 *
 * @param {string} userId
 * @param {QueryParamsBuilder} params
 * @return {Promote.<UserCore>}
 */
export async function getRawUserById(userId, params = new QueryParamsBuilder(), preventKey = 'user') {
  const partialUrl = api.createUrl(getResources(userId));

  params.addInclude('linkedClients', 'linkedAdvertisers', 'linkedSalesforceUser', 'linkedPublishers');

  return await api.get(partialUrl, params.buildWithoutPage(), { preventKey });
}
