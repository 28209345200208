import { api } from '.';
import { USER_RESOURCE } from '@/services/keys';

/**
 *
 * @param {User} user
 * @returns {Promise<object>}
 */
export async function deleteUser(user) {
  const partialUrl = api.createUrl({
    [USER_RESOURCE]: user.id,
  });
  return await api.delete(partialUrl);
}
