import { getRawUserById } from './getRawUserById';
import { USER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

/**
 * @param {string} userId
 */
export const getResources = userId => ({
  [USER_RESOURCE]: userId,
});

/**
 *
 * @param {string} userId
 * @param {QueryParamsBuilder} params
 * @return {Promote.<UserCore>}
 */
export async function getUserById(userId, params = new QueryParamsBuilder(), preventKey = 'user') {
  const { data } = await getRawUserById(userId, params, preventKey);
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}
