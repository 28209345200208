import { api } from '.';
import { USER_RESOURCE } from '@/services/keys';
/**
 *
 * @param {User} user
 * @return {Promote.<string>}
 */
export async function createUser(user) {
  const partialUrl = api.createUrl({
    [USER_RESOURCE]: undefined,
  });

  const { data } = await api.create(partialUrl, user.payload());
  return data.user.id;
}
