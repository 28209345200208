import { getUserById } from './getUserById';
import { createUser } from './createUser';
import { updateUser } from './updateUser';
import { getUsers, getUsersWithoutIncludes } from './getUsers';
import { deleteUser } from './deleteUser';
import { core } from '..';

export const api = core;

export { getUserById, createUser, updateUser, getUsers, deleteUser, getUsersWithoutIncludes };
