import { api } from '.';
import { USER_RESOURCE } from '@/services/keys';
import QueryParamsBuilder from '@/model/shared/QueryParamsBuilder';
import coreEntityParser from '@/entities/Core/CoreEntityParser';
import ResponseBuilder from '@/model/shared/ResponseBuilder';

export const getResources = () => ({
  [USER_RESOURCE]: undefined,
});

/**
 *
 * @param {QueryParamsBuilder} params
 * @param {Object} config
 * @return {Promote.<{meta:object,data:UserCore[]}>}
 */
export async function getUsers(params = new QueryParamsBuilder(), config = {}) {
  const partialUrl = api.createUrl(getResources());
  params.addInclude('linkedClients', 'linkedAdvertisers', 'linkedSalesforceUser', 'linkedPublishers');

  const { data } = await api.get(partialUrl, params.build(), config);
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}

/**
 *
 * @param {QueryParamsBuilder} params
 * @param {Object} config
 * @return {Promote.<{meta:object,data:UserCore[]}>}
 */
export async function getUsersWithoutIncludes(params = new QueryParamsBuilder(), config = {}) {
  const partialUrl = api.createUrl(getResources());

  const { data } = await api.get(partialUrl, params.build(), config);
  return new ResponseBuilder(coreEntityParser.parse(data), data.meta).build();
}
