import { api } from '.';
import { USER_RESOURCE } from '@/services/keys';

/**
 * @param {string} userId
 */
export const getResources = userId => ({
  [USER_RESOURCE]: userId,
});

/**
 *
 * @param {import('@/entities/Auth/UserCore').default} user
 * @return {Promote.<{data:object}>}
 */
export async function updateUser(user) {
  const partialUrl = api.createUrl(getResources(user.id));

  const { data } = await api.update(partialUrl, user.payload ? user.payload() : user);
  return data;
}
